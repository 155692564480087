html {
  font-size: 12px;
}

body {
  overflow: auto !important;
  padding-right: 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  width: -webkit-fill-available;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.nabati-logo {
  height: 100px;
  pointer-events: none;
}

.nabati-logo-home {
  height: 150px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* sidebar */

body {
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
  height: 100%;
}

main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

.b-example-divider {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
  vertical-align: middle;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle {
  outline: 0;
}

.icon-chev-right {
  left: 250px;
  display: inline-block;
  position: absolute;
}

.bg-gray {
  background-color: #707070;
}

.bg-soft-gray {
  background-color: #e5e5e5;
}

.bg-soft-tosca {
  background-color: #27C0CD;
}

.nav-item-tab[aria-expanded="true"] .icon-chev-right {
  transform: rotate(90deg);
}

/* Login */
.wrapper-login {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.bg-full-height {
  height: 150vh;
}

.bg-full-height-login, .bg-full-height-standard  {
  height: 100vh;
}

.form-login {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.form-login .checkbox {
  font-weight: 400;
}

.form-login .form-floating:focus-within {
  z-index: 2;
}

.form-login input[type="email"],
.form-login input[type="password"] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.login-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;
  background-color: white;
}

.callout-warning {
  border-left-color: red;
}

.callout-bg {
  background-color: #f5f5f5;
}

.cursor-pointer {
  cursor: pointer;
}

/* input datetime */
.DayPickerInput {
  /* flex: 0 0 auto;
  width: 93%; */
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.DayPickerInput>input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DayPicker-Footer {
  text-align: center;
}

.DayPicker-input-group {
  position: relative;
  display: flex;
  width: 100%;
}

.DayPicker-input-group-text {
  display: flex;
  align-items: center;
  padding: 2px 7px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

/* table dropdown */
.dropdown-menu.show {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  width: 100%;
  transform: translate(-0%, 31px) !important;
  max-height: 300px;
  overflow: auto;
}

.dropdown-menu.batch.show {
  position: fixed !important;
  inset: 0px auto auto 0px;
  margin: 0px;
  max-width: 40% !important;
  transform: translate(142%, 30em) !important;
  max-height: 150px;
  overflow: auto;
}

.dropdown-menu.batch-gi.show {
  position: fixed !important;
  inset: 0px auto auto 0px;
  margin: 0px;
  max-width: 40% !important;
  transform: translate(75%, 40em) !important;
  max-height: 150px;
  overflow: auto;
}

.dropdown-table {
  display: table;
  width: 100%;
}

.dropdown-table-row {
  display: table-row;
}

.dropdown-table-row.header {
  font-weight: bold;
  color: white;
  background-color: black;
}

.dropdown-table-row.child:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.dropdown-table-heading {
  background-color: #EEE;
  display: table-header-group;
}

.dropdown-table-cell,
.dropdown-table-head {
  border: 1px solid rgb(222, 226, 230);
  display: table-cell;
  padding: 3px 10px;
  cursor: pointer;
}

.dropdown-table-heading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold;
}

.dropdown-table-footer {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}

.dropdown-table-body {
  display: table-row-group;
}

.dropdown-nodata {
  text-align: center;
}

.edot-floating {
  display: inline-block;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: auto;
  padding: 0px 10px 10px;
}

.edot-floating img {
  height: 250px;
}

/*Custom CSS Component*/
.table{
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
}

.table th, .table td{
  padding: 4px;
  border: solid 1px #333;
}

.table tbody th {
  background-color: #fff !important;
  color: #333 !important;
}

.table td.center{
  text-align: center;
}
.table td.right{
  text-align: right;
}

.table th {
  background-color: #333;
  color: #fff;
  text-align: left;
}

.panelContainer {
  overflow: hidden;
  background-color: #fff;
  padding: 5px;
  border: solid 1px #999;
  box-shadow: 0 0 141px 40px rgba(79,79,79,0.73);
  -webkit-box-shadow: 0 0 141px 40px rgba(79,79,79,0.73);
  -moz-box-shadow: 0 0 141px 40px rgba(79,79,79,0.73);
}

.panelFooter {
  padding: 10px;
  text-align: right;
}

.panelTabel {
 background-color: #fff; 
 border-collapse: collapse;
}

.panelTabel th {
  background-color: #333;
  color: #fff;
  text-align: left;
}

.panelTabel td {
  text-overflow: ellipsis;
}

.panelTabel th, .panelTabel td {
  padding: 2px;
  border: solid 1px #333;
  min-width: 120px;
  max-width: 120px;
}

.panelTabel th input {
  width: 100%;
}

.panelTabel tbody, .panelTabel thead {
    display: block;
}

.panelTabel tbody {
   overflow-y: auto;
   overflow-x: hidden;
   height: 200px;
}

.panelTabel tbody tr:hover {
  background-color: #fcf297;
  cursor: pointer;
}

/*CSS TABLE GRID*/
div.grid-container {
  max-height: 36em;
  overflow: scroll;
  position: relative;
}

div.grid-container table {
  position: relative;
  border-collapse: collapse;
  width: 100%;
}

div.grid-container table td, div.grid-container table th{
  padding: 4px;
  border: solid 1px #333;
}

div.grid-container thead th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  background: #000;
  color: #FFF;
}

div.grid-container thead th div{
  margin-top: 5px;
}

div.grid-container tbody th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background: #FFF;
  border-right: 1px solid #CCC;
}

/*PAGINATION*/
.grid-pagination {
  display: flex;
  list-style: none;
}
.grid-pagination button {
  padding: 5px;
  border: 1px solid #dee2e6;
  background-color: #fff;
  min-width: 25px;
  color: #0d6efd;
  box-sizing: border-box;
  font-size: 1em !important;
}
.grid-pagination button.page-gap{
  border: solid 1px #fff;
}
.grid-pagination button:disabled {
  background-color: #ccc;
}

#loader{
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  background-color: #1b1e4f38;
  display: flex;
  justify-content: center;
  align-items: center;
}
#loader>.content{
  background-color: #fff;
  color: #000;
  border-radius: 1.5em;
  padding: .5rem 45px;
}
.form-control.invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
.modal-90w{
  min-width: 90vw;
}